<template>
  <div v-if="hasWizard">
    <h2>{{ $t('page.settings.social_accounts.title') }}</h2>
    <p class="mb-12">
      {{ $t('page.settings.social_accounts.description') }}
    </p>

    <profile-social-accounts />
  </div>
  <div v-else>
    <incompleted-wizard-message />
  </div>
</template>

<script lang="ts">
import IncompletedWizardMessage from '@/components/wizard/IncompletedWizardMessage.vue'
import { defineComponent } from 'vue'
import { useProfileWizardStep } from '@/composables/profile/useProfileWizardStep'
import ProfileSocialAccounts from '@/components/profile/ProfileSocialAccounts.vue'

export default defineComponent({
  components: {
    IncompletedWizardMessage,
    ProfileSocialAccounts,
  },

  setup () {
    const { hasWizard } = useProfileWizardStep()

    return {
      hasWizard
    }
  }
})
</script>
