
import { defineComponent } from 'vue'
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import ProfileMenu from '@/components/profile/ProfileMenu.vue'

export default defineComponent({
  components: {
    DashboardContent,
    ProfileMenu,
  },
  setup () {
  }
})
