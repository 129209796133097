<template>
  <div class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 gap-4">
    <template v-for="(socialMedia, n) in socialMedias" :key="n">
      <component :is="socialMedia"/>
    </template>
  </div>
</template>

<script lang="ts">
import { currentUser } from '@/composables/user/currentUser'
import { defineAsyncComponent, defineComponent, unref } from 'vue'

export default defineComponent({
  setup () {
    const { user } = currentUser()

    const socialMedias = [
      defineAsyncComponent(() => import('@/components/social-media/MetaModal.vue')),
      defineAsyncComponent(() => import('@/components/social-media/LinkedinModal.vue')),
      defineAsyncComponent(() => import('@/components/social-media/TwitterModal.vue')),
      defineAsyncComponent(() => import('@/components/social-media/TiktokModal.vue')),
      defineAsyncComponent(() => import('@/components/social-media/YoutubeModal.vue')),
    ]

    if (unref(user).id !== 'skHi69P8radCAUp0adgipYY6tpi1') {
      socialMedias.pop()
      socialMedias.pop()
    }

    return {
      socialMedias
    }
  }
})
</script>
