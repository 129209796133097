import { computed, toRaw } from 'vue'
import { useStore } from 'vuex'
import { useProfileService } from '@/services/firestore/profile'
import { useRouter } from 'vue-router'
import Swal from 'sweetalert2'
import { useI18n } from 'vue-i18n'
import { useProfile } from './useProfile'

export const useCreateProfile = () => {
  const store = useStore()

  const { t } = useI18n()

  const router = useRouter()

  const { createProfileService } = useProfileService()

  const {
    setCurrent
  } = useProfile()

  const createProfile = () => {
    const profiles = computed(() => toRaw(store.getters['profiles/all']))
  
    Swal.fire({
      title: t('state.new_profile.form.title'),
      input: 'text',
      showCancelButton: true,
      confirmButtonText: t('state.new_profile.form.action_confirm'),
      cancelButtonText: t('state.new_profile.form.action_cancel'),
      preConfirm: (name) => {
        if (!name) {
          Swal.showValidationMessage(
            t('state.new_profile.form.validate_empty_name_message')
          )
        }
        if (profiles.value.find((profile: { name: string }) => profile.name === name)) {
          Swal.showValidationMessage(
            t('state.new_profile.form.validate_duplicated_name_message')
          )
        }
        return name
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(async (result) => {
      if (result && result.value) {
        const profileId = await createProfileService(result.value)
        if (profileId) {
          setCurrent(profileId)
        }
        router.replace({ name: 'change-profile' })
      }
    })
  }

  return {
    createProfile
  }
}
