
import { defineComponent, ref, watchEffect } from 'vue'
import { currentUser } from '@/composables/user/currentUser'
import BaseInput from '../base/BaseInput.vue'
import { useToast } from '@/composables/useToast'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { BaseInput },
  setup () {
    const { t } = useI18n()
    
    const form = ref({
      name: '',
      // email: '',
      // document: '',
      phone: ''
    })
    const address = ref('')

    const { user, updateContactInfo } = currentUser()

    watchEffect(() => {
      const currentUser = user.value

      if (currentUser?.billing?.customer) {
        const customer = currentUser.billing.customer
        form.value = {
          name: customer.name,
          // document: customer.document,
          // email: customer.email,
          phone: customer.phone
        }
      }

      if (currentUser?.billing?.address) {
        const { street, number, complement, district, city, state, zip } = currentUser.billing.address
        address.value = `Rua ${street}. nº ${number}. ${complement}. ${district}, ${city} - ${state}. CEP:${zip}.`
      }
    })

    // watch(user, (currentUser) => {
    //   if (currentUser && currentUser.billing && currentUser.billing.customer) {
    //     const customer = currentUser.billing.customer
    //     form.value = {
    //       name: customer.name,
    //       document: customer.document,
    //       email: customer.email,
    //       phone: customer.phone
    //     }
    //   }

    //   if (currentUser && currentUser.billing && currentUser.billing.address) {
    //     const { street, number, complement, district, city, state, zip } = currentUser.billing.address
    //     address.value = `Rua ${street}. nº ${number}. ${complement}. ${district}, ${city} - ${state}. CEP:${zip}.`
    //   }
    // })

    const update = async () => {
      try {
        updateContactInfo({
          name: form.value.name,
          phone: form.value.phone
        })

        useToast.fire({
          icon: 'success',
          title: t('state.update_profile.title'),
        })
      } catch (error) {
        useToast.fire({
          icon: 'error',
          title: t('sentence.default_error_title'),
          text: t('sentence.default_error')
        })
      }
    }

    return {
      form,
      address,
      user,
      update
    }
  }
})
