import { auth } from "@/plugins/firebase";

export const resetPassword = async (newpassword: string) => {
    try {
      await auth.currentUser?.updatePassword(newpassword)
    } catch (error) {
        // @ts-ignore
        const code = error.code

        let errorMessage
        switch (code) {
            case 'auth/requires-recent-login':
              errorMessage = 'state.password.recent_login'
              break
            case 'auth/weak-password':
              errorMessage = 'state.password.weak_password'
              break
            
        }
        return errorMessage
    }
}