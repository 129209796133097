<template>
  <div class="grid lg:grid-cols-3 gap-4">
    <div class="card lg:col-span-3">
      <div class="card-header">
        <div class="card-title">
          {{ $t('table.account_profiles.title') }}
        </div>
      </div>
      <div class="card-body h-auto">
        <profiles-list />
        <div class="flex justify-end mt-4 w-full">
          <button
            class="btn btn-primary"
            @click="handleCreateProfile"
          >
            {{ $t('action.create_profile') }}
          </button>
        </div>
      </div>
    </div>

    <div class="card">
      <div class="card-header">
        <div class="card-title">
          {{ $t('form.change_auth_info.title') }}
        </div>
      </div>
      <div class="card-body">
        <form class="flex flex-col space-y-6" @submit.prevent="handleResetPassword">
          <base-input
            :label="$t('form.label.email')"
            name="email"
            type="email"
            v-model="user.email"
            autocomplete="username"
            left-icon="far fa-envelope" 
            :readonly="true"
            :disabled="true"/>

          <base-input
            :label="$t('form.label.new_password')"
            name="password"
            type="password"
            autocomplete="current-password"
            left-icon="fas fa-lock" 
            v-model="newPassword"
            />

          <div class="flex justify-end">
            <button
              type="submit"
              class="btn btn-primary"
            >
              {{ $t('action.save') }}
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="card-title">
          {{ $t('form.contact_info.title') }}
        </div>
      </div>
      <div class="card-body">
        <billing-info />
      </div>
    </div>
    <div class="card">
      <div class="card-header">
        <div class="card-title">
          {{ $t('form.credit_card.title') }}
        </div>
      </div>
      <div class="card-body">
        <change-credit-card />
      </div>
    </div>

    <div class="card lg:col-span-3">
      <div class="card-header">
        <div class="card-title">
          {{ $t('action.unsubscribe') }}
        </div>
      </div>
      <div class="card-body">
        <div class="flex flex-col lg:flex-row justify-between items-center gap-4">
          <p>{{ $t('page.cancellation_survey.sentence.description') }}</p>
          <button
            class="btn btn-danger whitespace-nowrap"
            @click="askCancel"
          >
            {{ $t('action.unsubscribe') }}
          </button>
        </div>
      </div>
    </div>
    <!-- <div class="card">
      <div class="card-header">
        <div class="card-title">
          {{ $t('table.invoices_list.title') }}
        </div>
      </div>
      <div class="card-body">
        <invoices-list />
      </div>
    </div> -->
  </div>
</template>

<script lang="ts">
import { defineComponent ,ref } from 'vue'
import { currentUser } from '@/composables/user/currentUser'
// import InvoicesList from '@/components/myAccount/invoicesList.vue'
import ProfilesList from '@/components/myAccount/profilesList.vue'
import BillingInfo from '@/components/myAccount/BillingInfo.vue'
import ChangeCreditCard from '@/components/myAccount/ChangeCreditCard.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import Swal from 'sweetalert2'
import { useToast } from '@/composables/useToast'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {resetPassword} from '@/api/auth/updatePassword'
import { useCreateProfile } from '@/composables/profile/createProfile'
import { useProfile } from '@/composables/profile/useProfile'

export default defineComponent({
  components: {
    // InvoicesList,
    ProfilesList,
    BillingInfo,
    ChangeCreditCard,
    BaseInput
  },

  setup () {
    const newPassword = ref('')

    const router = useRouter()

    const { t } = useI18n()

    const { user } = currentUser()

    const { createProfile } = useCreateProfile()

    const {
      isFreeTrial,
    } = useProfile()

    const askCancel = () => {
      Swal.fire({
        text: t('state.subscription_cancel.message'),
        showCancelButton: true,
        confirmButtonText: t('state.subscription_cancel.action_confirm'),
        cancelButtonText: t('state.subscription_cancel.action_cancel')
      }).then(async ({ value }) => {
        if (value) {
          router.replace({ name: 'unsubscribe-survey' })
        }
      })
    }

    const handleResetPassword = async () => {
      const errorMessage  = await resetPassword(newPassword.value)

      if(errorMessage){
        useToast.fire({
          icon: 'error',
          title: t('state.password.title_error'),
          text: t(errorMessage)
        })
      }else{
        useToast.fire({
          icon: 'success',
          title: t('state.password.title_sucess'),
        })
      }
    }

    const handleCreateProfile = () => {
      let message = `${t('state.new_profile.message')}`
      if (isFreeTrial.value) {
        message = `
          ${message}
          <br><br>
          <div class="alert alert-info flex space-x-2">
            <i class="fas fa-info-circle m-1"></i>
            <div>
              ${t('state.new_profile.trial_message')}
            </div>
          </div>
        `
      }
      Swal.fire({
        title: t('state.new_profile.title'),
        html: message,
        showCancelButton: true,
        confirmButtonText: t('state.new_profile.action_confirm'),
        cancelButtonText: t('state.new_profile.action_cancel')
      }).then(({ value }) => {
        if (value) {
          createProfile()
        }
      })
    }

    return {
      user,
      askCancel, 
      handleResetPassword,
      handleCreateProfile,
      newPassword
    }
  }
})
</script>
