<template>
  <div class="w-full md:w-max">
    <div class="card px-2 md:px-4">
      <ul class="profile-tabs flex justify-between items-center">
        <router-link
          v-for="route in routes"
          :key="`routerlink-${route.to.name}`"
          :to="route.to"
          active-class="profile-tab-active"
          custom
          v-slot="{ isActive }"
        >
          <li
            v-text="route.label"
            @click="selectedRoute = route.to"
            :class="{ 'current-tab': isActive }"
          />
        </router-link>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
  setup () {
    const router = useRouter()

    const { t } = useI18n()

    const routes = computed(() => {
      return [
        { to: { name: 'settings-profile'}, label: t('nav.settings.profile') },
        { to: { name: 'settings-social-medias'}, label: t('nav.settings.social_accounts') },
        { to: { name: 'settings-account'}, label: t('nav.settings.account') },
        { to: { name: 'settings-preferences'}, label: t('nav.settings.preferences') },
      ]
    })

    const selectedRoute = ref<any>(null)

    watch(selectedRoute, (route) => {
      if (route) {
        router.push(route)
      }
    })

    return {
      routes,
      selectedRoute
    }
  }
})
</script>

<style scoped>
.profile-tabs li {
  cursor: pointer;
  text-align: center;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  -webkit-transition-property: background-color, border-color, color, fill, stroke, opacity, -webkit-box-shadow, -webkit-transform;
  -o-transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, -webkit-box-shadow, transform, -webkit-transform;
  -webkit-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -o-transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  -webkit-transition-duration: 150ms;
  -o-transition-duration: 150ms;
  transition-duration: 150ms;
  -webkit-transition-duration: 500ms;
  -o-transition-duration: 500ms;
  transition-duration: 500ms;
}
.profile-tabs li:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(219, 234, 254, var(--tw-bg-opacity));
}
.profile-tabs li.current-tab {
  --tw-text-opacity: 1;
  color: rgba(59, 130, 246, var(--tw-text-opacity));
  border-bottom-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgba(59, 130, 246, var(--tw-border-opacity));
}
@media (min-width: 768px) {
  .profile-tabs li {
    font-size: 1rem;
    line-height: 1.5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}
</style>
