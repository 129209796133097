<template>
  <div class="overflow-x-auto">
    <table>
      <thead>
        <tr>
          <th>{{ $t('table.account_profiles.header_profile') }}</th>
          <th class="text-center hidden lg:table-cell">{{ $t('table.account_profiles.header_created_at') }}</th>
          <th class="text-center">{{ $t('table.account_profiles.header_status') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="profile in profiles" :key="profile.id">
          <td>
            <div class="flex justify-start items-center flex-nowrap">
              <img
                class="flex-none w-8 h-8 rounded-full object-cover shadow-lg mr-2"
                :src="getProfileImage(profile)"
              >
              <div>
                <div class="font-semibold whitespace-nowrap">{{ profile.name }}</div>
                <div class="text-xs text-gray-500">{{ profile.category?.name }}</div>
              </div>
            </div>
          </td>
          <td class="text-center hidden lg:table-cell">{{ profile.createdAt ? format(profile.createdAt.seconds * 1000) : '' }}</td>
          <td>
            <div class="w-full flex justify-center">
              <span
                class="badge-outline text-sm text-green-600"
                v-if="profile.plan && profile.plan.active"
              >
                {{ $t('sentence.active') }}
              </span>
              <span
                class="badge-outline text-sm text-red-700"
                v-else
              >
                {{ $t('sentence.inactive') }}
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { userProfiles } from '@/composables/profile/userProfiles'
import { getProfileImage } from '@/composables/profile/profileImage'
import { useDateFns } from '@/composables/locale/useDateFns'

export default defineComponent({
  setup () {
    const { profiles } = userProfiles()

    const { format } = useDateFns()

    return {
      profiles,
      getProfileImage,
      format
    }
  }
})
</script>
