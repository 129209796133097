<template>
  <div class="card p-4 flex flex-col justify-center items-center mx-auto h-72 w-full lg:w-3/4">
    <div
      class="text-lg text-center leading-relaxed"
      v-html="$t('page.settings.social_accounts.sentence.incompleted_wizard-html')"
    />
    <router-link :to="{ name: 'wizard' }" class="btn btn-primary my-12">
      {{ $t('action.go_to_wizard') }}
    </router-link>
    <div
      class="text-sm text-center"
    >
      <base-you-need-help-message />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import BaseYouNeedHelpMessage from '@/components/base/BaseYouNeedHelpMessage.vue'

export default defineComponent({
  components: {
    BaseYouNeedHelpMessage,
  }
})
</script>