
import { defineComponent } from 'vue'
import { userProfiles } from '@/composables/profile/userProfiles'
import { getProfileImage } from '@/composables/profile/profileImage'
import { useDateFns } from '@/composables/locale/useDateFns'

export default defineComponent({
  setup () {
    const { profiles } = userProfiles()

    const { format } = useDateFns()

    return {
      profiles,
      getProfileImage,
      format
    }
  }
})
