
import IncompletedWizardMessage from '@/components/wizard/IncompletedWizardMessage.vue'
import { defineComponent } from 'vue'
import { useProfileWizardStep } from '@/composables/profile/useProfileWizardStep'
import ProfileSocialAccounts from '@/components/profile/ProfileSocialAccounts.vue'

export default defineComponent({
  components: {
    IncompletedWizardMessage,
    ProfileSocialAccounts,
  },

  setup () {
    const { hasWizard } = useProfileWizardStep()

    return {
      hasWizard
    }
  }
})
