<template>
  <div class="h-[calc(100%-54px)]">
    <div
      v-if="empty"
      class="flex justify-center items-center h-full"
    >
      <base-modal
        v-model:open="isOpen" hide-footer size="medium"
      >
        <template v-slot:button>
          <button class="btn btn-primary outline">
            Alterar cartão de crédito
          </button>
        </template>

        <div class="card-header">
          <div class="card-title">
            Alterar cartão de crédito
          </div>
        </div>
        <div class="card-body">
          <div>
            <div class="space-y-6 md:space-y-0 md:flex md:space-x-6">
              <base-input
                label="Número do cartão"
                name="cardNumber"
                type="text"
                v-model="cardNumber"
                placeholder="1234 1234 1234 1234"
                :right-icon="brandClass"
                :mask="cardNumberMask"
                :autofocus="true"
                :has-error="!!cardNumberErrorMessage"
                :error-message="cardNumberErrorMessage"
              />

              <base-input
                label="Nome impresso no cartão"
                name="cardHolderName"
                type="text"
                v-model="cardHolderName"
                :has-error="!!cardHolderNameErrorMessage"
                :error-message="cardHolderNameErrorMessage"
              />
            </div>

            <div class="flex space-x-6">
              <base-input
                label="Data de validade"
                name="cardExpirationDate"
                type="text"
                v-model="cardExpirationDate"
                placeholder="MM/AA"
                mask="##/##"
                :has-error="!!cardExpirationDateErrorMessage"
                :error-message="cardExpirationDateErrorMessage"
              />
          
              <base-input
                label="Código de segurança"
                name="cardCVV"
                type="text"
                v-model="cardCVV"
                placeholder="CVC"
                :mask="cvvMask"
                :has-error="!!cardCVVErrorMessage"
                :error-message="cardCVVErrorMessage"
              />
            </div>
          </div>
        </div>
        <div class="card-footer flex justify-end gap-4">
          <button class="btn" @click.prevent="closeModal">
            {{ $t('action.cancel') }}
          </button>
          <button class="btn btn-primary" @click.prevent="finish">
            {{ $t('action.finish') }}
          </button>
        </div>
      </base-modal>
    </div>
    <div
      v-else
      class="flex justify-center items-center h-full"
    >
      <p class="text-center">
        Você já possui uma solicitação de troca de cartão em andamento.
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { useToggle } from '@/composables/useToggle'
import { computed, defineComponent, onMounted, ref } from 'vue'
import BaseModal from '../base/BaseModal.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import creditCardType from 'credit-card-type'
import { helpers } from '@vuelidate/validators'
import useVuelidate from '@vuelidate/core'
import { useI18n } from 'vue-i18n'
import { auth, firestore } from '@/plugins/firebase'
import { useToast } from '@/composables/useToast'

export default defineComponent({
  components: {
    BaseModal,
    BaseInput
  },

  setup () {
    const { t } = useI18n()

    const {
      isActive: isOpen,
      setInactive: closeModal,
    } = useToggle(false)

    const userId = auth.currentUser?.uid
    const userEmail = auth.currentUser?.email
    const empty = ref<boolean>(true)
    const cardNumber = ref<string>('')
    const cardHolderName = ref<string>('')
    const cardExpirationDate = ref<string>('')
    const cardCVV = ref<string>('')

    const creditCardTypeInfo = computed(() => {
      const creditCardInfo = creditCardType(cardNumber.value)
      return creditCardInfo.length ? creditCardInfo[0] : null
    })

    const cardNumberMask = computed(() => {
      if (creditCardTypeInfo.value) {
        const card = creditCardTypeInfo.value
        const cardNumberLength = card.lengths[0]

        const offsets = [0, ...card.gaps, cardNumberLength]
        const components = []

        let currentCardNumber = ''
        for (let i = 0; i <= cardNumberLength; i++) {
          currentCardNumber = currentCardNumber + '1'
        }

        for (let i = 0; offsets[i] < cardNumberLength; i++) {
          const start = offsets[i];
          const end = Math.min(offsets[i + 1], cardNumberLength);
          components.push(currentCardNumber.substring(start, end));
        }

        return components.join(" ").replace(/\d/g, '#')
      }

      return '#### #### #### ####'
    })

    const cvvMask = computed(() => {
      const mask = '####'
      const cvvSize = creditCardTypeInfo.value ? creditCardTypeInfo.value.code.size : 3

      return mask.substring(0, cvvSize)
    })

    const brandClass = computed(() => {
      const cardBrandToClass: {[key: string]: string} = {
        'visa': 'fab fa-cc-visa',
        'mastercard': 'fab fa-cc-mastercard',
        'american-express': 'fab fa-cc-amex',
        'discover': 'fab fa-cc-discover',
        'diners-club': 'fab fa-cc-diners-club',
        'jcb': 'fab fa-cc-jcb',
        'unionpay': 'far fa-credit-card',
        'maestro': 'far fa-credit-card',
        'mir': 'far fa-credit-card',
        'elo': 'far fa-credit-card',
        'hiper': 'far fa-credit-card',
        'hipercard': 'far fa-credit-card',
        'unknown': 'far fa-credit-card',
      }

      const brand = cardNumber.value.length && creditCardTypeInfo.value 
        ? creditCardTypeInfo.value.type
        : 'unknown'

      return `${cardBrandToClass[brand]} fa-2x`
    })

    const cardNumberValidator = (value: string) => {
      if (!creditCardTypeInfo.value) {return false}

      const cardNumberLength = creditCardTypeInfo.value.lengths[0]
      return value.replace(/\D/g, '').length === cardNumberLength
    }

    const cardCvvValidator = (value: string) => {
      if (!creditCardTypeInfo.value) {return false}

      const cvvSize = creditCardTypeInfo.value.code.size
      return value.length === cvvSize
    }

    const cardExpiryValidator = (value: string) => {
      let [month, year] = Array.from(value.split(/[\s/]+/, 2))

      if (!month || !year) { return false }

      if (!/^\d+$/.test(month)) { return false }
      if (!/^\d+$/.test(year)) { return false }
      if (!(1 <= Number(month) && Number(month) <= 12)) { return false }

      if (year.length === 2) {
        if (Number(year) < 70) {
          year = `20${year}`
        } else {
          year = `19${year}`
        }
      }

      if (year.length !== 4) { return false }

      const expiry = new Date(Number(year), Number(month))
      const currentTime = new Date

      // Months start from 0 in JavaScript
      expiry.setMonth(expiry.getMonth() - 1)

      // The cc expires at the end of the month,
      // so we need to make the expiry the first day
      // of the month after
      expiry.setMonth(expiry.getMonth() + 1, 1)

      return expiry > currentTime
    }

    const cardRequiredValidator = (value: string) => {
      return !!value.trim().length
    }

    const rules = computed(() => {
      return {
        cardNumber: {
          cardRequired: helpers.withMessage(t('validations.required'), cardRequiredValidator),
          cardNumber: helpers.withMessage(t('validations.credit_card_number'), cardNumberValidator)
        },
        cardHolderName: {
          cardRequired: helpers.withMessage(t('validations.required'), cardRequiredValidator)
        },
        cardExpirationDate: {
          cardRequired: helpers.withMessage(t('validations.required'), cardRequiredValidator),
          cardExpiry: helpers.withMessage(t('validations.credit_card_expiry'), cardExpiryValidator)
        },
        cardCVV: {
          cardRequired: helpers.withMessage(t('validations.required'), cardRequiredValidator),
          cardCVV: helpers.withMessage(t('validations.credit_card_cvv'), cardCvvValidator)
        },
      }
    })

    const v$ = useVuelidate(rules, {
      cardNumber,
      cardHolderName,
      cardExpirationDate,
      cardCVV
    })

    const cardNumberErrorMessage = computed(() => v$.value.cardNumber.$errors[0]?.$message as string || undefined)
    const cardHolderNameErrorMessage = computed(() => v$.value.cardHolderName.$errors[0]?.$message as string || undefined)
    const cardExpirationDateErrorMessage = computed(() => v$.value.cardExpirationDate.$errors[0]?.$message as string || undefined)
    const cardCVVErrorMessage = computed(() => v$.value.cardCVV.$errors[0]?.$message as string || undefined)

    const isFormValid = () => {
      v$.value.$touch()
      v$.value.$validate()
      return !v$.value.$invalid
    }

    const finish = async () => {
      if (isFormValid()) {
        await saveSolicitation()
      }
    }

    const fetchSolicitation = async () => {
      const query = await firestore
        .collection('changeCreditCard')
        .where('user', '==', userId)
        .get()

      empty.value = query.size === 0
    }

    const saveSolicitation = async () => {
      const payload = {
        cardNumber: cardNumber.value,
        cardHolderName: cardHolderName.value,
        cardExpirationDate: cardExpirationDate.value,
        cardCVV: cardCVV.value,
        user: userId,
        email: userEmail,
        createdAt: new Date()
      }

      try {
        await firestore
          .collection('changeCreditCard')
          .add(payload)
  
        closeModal()
        empty.value = false

        useToast.fire({
          icon: 'success',
          title: t('state.update_profile.title'),
        })
      } catch (error) {
        useToast.fire({
          icon: 'error',
          title: t('sentence.default_error_title'),
          text: t('sentence.default_error')
        })
      }
    }

    onMounted(async () => {
      await fetchSolicitation()
    })

    return {
      isOpen,
      closeModal,
      empty,
      cardNumberMask,
      cvvMask,
      brandClass,
      cardNumber,
      cardHolderName,
      cardExpirationDate,
      cardCVV,
      cardNumberErrorMessage,
      cardHolderNameErrorMessage,
      cardExpirationDateErrorMessage,
      cardCVVErrorMessage,
      finish
    }
  }
})
</script>
