<template>
  <div>
    <div
      class="flex flex-col gap-8"
      v-if="hasWizard"
    >
      <div v-for="(session, index) in sessions" :key="index">
        <keep-alive>
          <component
            :is="session.component"
            :form="form"
            :currentErrors="currentErrors"
            @update:form="updateForm"
          />
        </keep-alive>
      </div>
    </div>
    <div v-else>
      <incompleted-wizard-message />
    </div>
  </div>
</template>

<script lang="ts">
import { defineAsyncComponent, defineComponent, ref, toRaw, unref } from 'vue'
import IncompletedWizardMessage from '@/components/wizard/IncompletedWizardMessage.vue'
import { useProfileWizardStep } from '@/composables/profile/useProfileWizardStep'
import { useProfile } from '@/composables/profile/useProfile'
import useVuelidate from '@vuelidate/core'
import { required, url } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { useProfileService } from '@/services/firestore/profile'
import { useToast } from '@/composables/useToast'
import { useDebounceFn } from '@vueuse/core'
import { useScrollTop } from '@/composables/useScrollTop'

export default defineComponent({
  components: {
    IncompletedWizardMessage,
  },
  setup () {
    const { hasWizard } = useProfileWizardStep()

    const { profile } = useProfile()

    const { updateProfile } = useProfileService()

    const { t } = useI18n()

    const { locale } = useI18n()

    const {
      name = '',
      profilePick = null,
      website = null,
      category = null,
      keywords = [],
      description = '',
      competitors = [],
      hashtags = [],
      settings = {},
      specialization = '',
      audience = '',
      voiceTone = {
        humor: '',
        formality: '',
        respectfulness: '',
        enthusiasm: '',
      },
      contentPurpose = '',
      profileProduct = '',
      language = ''
    } = unref(profile)

    const form = ref({
      name,
      profilePick,
      website,
      category,
      keywords,
      description,
      competitors,
      hashtags,
      autoSchedule: settings.autoSchedule || (settings?.autoScheduleFreeContent || settings?.autoSchedulePaidContent) || false,
      specialization,
      audience,
      voiceTone,
      contentPurpose,
      profileProduct,
      language: language || locale.value || ''
    })

    const validateRules = {
      name: {
        required
      },
      website: {
        url
      },
      category: {
        required
      },
      keywords: {
        required,
        keywordsCount: (value: any) => Object.keys(value).length === 3
      },
      specialization: {
        required,
        wordsCount: (value: any) => `${value}`.trim().length >= 20
      },
      audience: {
        required,
        wordsCount: (value: any) => `${value}`.trim().length >= 20
      },
      contentPurpose: {
        required,
        wordsCount: (value: any) => `${value}`.trim().length >= 20
      },
      profileProduct: {
        required,
        wordsCount: (value: any) => `${value}`.trim().length >= 20
      },
      language: {
        required
      }
    }

    const validatorMessages: { key: string; validation: string; message: string }[] = [
      {
        key: 'name',
        validation: 'name.required',
        message: t('page.settings.profile_identification.empty_error_message')
      },
      {
        key: 'website',
        validation: 'website.url',
        message: t('page.settings.profile_identification.form.website.invalid_error_message')
      },
      {
        key: 'category',
        validation: 'category.required',
        message: t('page.settings.category.form.category.empty_error_message')
      },
      {
        key: 'keywords',
        validation: 'keywords.required',
        message: t('page.settings.category.form.keyword.empty_error_message')
      },
      {
        key: 'keywords',
        validation: 'keywords.keywordsCount',
        message: t('page.settings.category.form.keyword.empty_error_message')
      },
      {
        key: 'specialization',
        validation: 'specialization.required',
        message: t('validations.required')
      },
      {
        key: 'specialization',
        validation: 'specialization.wordsCount',
        message: t('validations.min_characters-dynamic', { min: 20 })
      },
      {
        key: 'audience',
        validation: 'audience.required',
        message: t('validations.required')
      },
      {
        key: 'audience',
        validation: 'audience.wordsCount',
        message: t('validations.min_characters-dynamic', { min: 20 })
      },
      {
        key: 'contentPurpose',
        validation: 'contentPurpose.required',
        message: t('validations.required')
      },
      {
        key: 'contentPurpose',
        validation: 'contentPurpose.wordsCount',
        message: t('validations.min_characters-dynamic', { min: 20 })
      },
      {
        key: 'profileProduct',
        validation: 'profileProduct.required',
        message: t('validations.required')
      },
      {
        key: 'profileProduct',
        validation: 'profileProduct.wordsCount',
        message: t('validations.min_characters-dynamic', { min: 20 })
      },
      {
        key: 'language',
        validation: 'language.required',
        message: t('validations.required')
      },
    ]

    const currentErrors = ref()

    const sessions = [
      {
        component: defineAsyncComponent(() => import('@/components/profile/ProfileIdentificationSettings.vue')),
      },
      {
        component: defineAsyncComponent(() => import('@/components/profile/ProfileLogoSettings.vue')),
      },
      {
        component: defineAsyncComponent(() => import('@/components/profile/ProfileAboutBusinessSettings.vue')),
      },
      {
        component: defineAsyncComponent(() => import('@/components/profile/ProfileCompetitorsSettings.vue')),
      },
      {
        component: defineAsyncComponent(() => import('@/components/profile/ProfileHashtagsSettings.vue')),
      },
      {
        component: defineAsyncComponent(() => import('@/components/profile/ProfileTagsSettings.vue')),
      },
      {
        component: defineAsyncComponent(() => import('@/components/profile/ProfileAutoScheduleSettings.vue')),
      },
      {
        component: defineAsyncComponent(() => import('@/components/profile/ProfileTemplateSettings.vue')),
      },
    ]

    const $v = useVuelidate(validateRules, form, { $autoDirty: true })

    const updateForm = useDebounceFn((value: any) => {
      form.value = {
        ...form.value,
        ...value
      }

      $v.value.$touch()

      handleSaveProfile()
    }, 500)

    const handleSaveProfile = async () => {
      currentErrors.value = []
      await $v.value.$validate()

      // console.log({
      //   invalid: $v.value.$invalid,
      //   form: form.value
      // })

      if ($v.value.$invalid) {
        // console.log($v.value.$errors)
        currentErrors.value = $v.value.$errors
          .map(error => {
            const { $property, $validator } = error
            const errorMessage = validatorMessages.find(({ validation }) => validation === `${$property}.${$validator}`)
            return errorMessage
          })
          .reduce((prev, curr) => {
            if (curr) {
              return {...prev, [curr.key]: curr.message}
            }
            return prev
          }, {} as any)

          const keys = Object.keys(currentErrors.value)
          if (keys.length) {
            setTimeout(() => {
              useScrollTop(`[name="${keys[0]}"]`)
            }, 200);
          }
      } else {
        await saveProfile()
      }
    }

    const saveProfile = async () => {
      const payload = {
        ...form.value,
        settings: {
          autoSchedule: form.value.autoSchedule
        }
      }

      delete payload.autoSchedule

      await updateProfile(toRaw(payload))

      useToast.fire({
        icon: 'success',
        title: t('state.update_profile.title'),
        text: t('state.update_profile.message')
      })
    }    

    return {
      hasWizard,
      form,
      sessions,
      currentErrors,
      updateForm,
      handleSaveProfile
    }
  }
})
</script>
