
import { defineComponent, computed, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'

export default defineComponent({
  setup () {
    const router = useRouter()

    const { t } = useI18n()

    const routes = computed(() => {
      return [
        { to: { name: 'settings-profile'}, label: t('nav.settings.profile') },
        { to: { name: 'settings-social-medias'}, label: t('nav.settings.social_accounts') },
        { to: { name: 'settings-account'}, label: t('nav.settings.account') },
        { to: { name: 'settings-preferences'}, label: t('nav.settings.preferences') },
      ]
    })

    const selectedRoute = ref<any>(null)

    watch(selectedRoute, (route) => {
      if (route) {
        router.push(route)
      }
    })

    return {
      routes,
      selectedRoute
    }
  }
})
