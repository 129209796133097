import { onMounted, ref, unref } from 'vue'
import { useProfile } from './useProfile'

export const useProfileWizardStep = () => {
  const { profile } = useProfile()
  const { step } = unref(profile)
  const hasWizard = ref(false)

  onMounted(() => {
    hasWizard.value = step === -1
  })

  return {
    hasWizard
  }
}