<template>
  <div class="card">
    <div class="card-body pt-4">
      {{ $t('sentence.language') }} <language-select />
    </div>
  </div>
</template>

<script lang="ts">
import languageSelect from '@/components/settings/languageSelect.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { languageSelect }
})
</script>
