
import { defineComponent ,ref } from 'vue'
import { currentUser } from '@/composables/user/currentUser'
// import InvoicesList from '@/components/myAccount/invoicesList.vue'
import ProfilesList from '@/components/myAccount/profilesList.vue'
import BillingInfo from '@/components/myAccount/BillingInfo.vue'
import ChangeCreditCard from '@/components/myAccount/ChangeCreditCard.vue'
import BaseInput from '@/components/base/BaseInput.vue'
import Swal from 'sweetalert2'
import { useToast } from '@/composables/useToast'
import { useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import {resetPassword} from '@/api/auth/updatePassword'
import { useCreateProfile } from '@/composables/profile/createProfile'
import { useProfile } from '@/composables/profile/useProfile'

export default defineComponent({
  components: {
    // InvoicesList,
    ProfilesList,
    BillingInfo,
    ChangeCreditCard,
    BaseInput
  },

  setup () {
    const newPassword = ref('')

    const router = useRouter()

    const { t } = useI18n()

    const { user } = currentUser()

    const { createProfile } = useCreateProfile()

    const {
      isFreeTrial,
    } = useProfile()

    const askCancel = () => {
      Swal.fire({
        text: t('state.subscription_cancel.message'),
        showCancelButton: true,
        confirmButtonText: t('state.subscription_cancel.action_confirm'),
        cancelButtonText: t('state.subscription_cancel.action_cancel')
      }).then(async ({ value }) => {
        if (value) {
          router.replace({ name: 'unsubscribe-survey' })
        }
      })
    }

    const handleResetPassword = async () => {
      const errorMessage  = await resetPassword(newPassword.value)

      if(errorMessage){
        useToast.fire({
          icon: 'error',
          title: t('state.password.title_error'),
          text: t(errorMessage)
        })
      }else{
        useToast.fire({
          icon: 'success',
          title: t('state.password.title_sucess'),
        })
      }
    }

    const handleCreateProfile = () => {
      let message = `${t('state.new_profile.message')}`
      if (isFreeTrial.value) {
        message = `
          ${message}
          <br><br>
          <div class="alert alert-info flex space-x-2">
            <i class="fas fa-info-circle m-1"></i>
            <div>
              ${t('state.new_profile.trial_message')}
            </div>
          </div>
        `
      }
      Swal.fire({
        title: t('state.new_profile.title'),
        html: message,
        showCancelButton: true,
        confirmButtonText: t('state.new_profile.action_confirm'),
        cancelButtonText: t('state.new_profile.action_cancel')
      }).then(({ value }) => {
        if (value) {
          createProfile()
        }
      })
    }

    return {
      user,
      askCancel, 
      handleResetPassword,
      handleCreateProfile,
      newPassword
    }
  }
})
