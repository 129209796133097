<template>
  <dashboard-content>
    <template v-slot:actions>
      <profile-menu/>
    </template>

    <div class="flex flex-col items-start space-x-4">
      <div class="w-full">
        <div class="my-4 w-full">
          <router-view key="profile-view"/>
        </div>
      </div>
    </div>
  </dashboard-content>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import DashboardContent from '@/components/dashboard/DashboardContent.vue'
import ProfileMenu from '@/components/profile/ProfileMenu.vue'

export default defineComponent({
  components: {
    DashboardContent,
    ProfileMenu,
  },
  setup () {
  }
})
</script>
