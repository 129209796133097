<template>
  <div>
    <form
      class="flex flex-col space-y-6"
      @submit.prevent="update"
    >
      <base-input
        :label="$t('form.label.full_name')"
        name="name"
        type="text"
        v-model="form.name"
        autocomplete="name"
        left-icon="far fa-user" />
      
      <!-- <base-input
        label="CPF/CNPJ"
        name="document"
        type="text"
        v-model="form.document"
        autocomplete="document"
        left-icon="far fa-id-card" /> -->

      <!-- <base-input
        :label="$t('form.label.email')"
        name="email"
        type="email"
        v-model="form.email"
        autocomplete="username"
        left-icon="far fa-envelope" /> -->

      <base-input
        label="Telefone / Whatsapp"
        name="phone"
        type="tel"
        v-model="form.phone"
        autocomplete="phone"
        left-icon="fas fa-phone" />
      
      <!-- <div>
        <label class="text-gray-600 py-1">
          <i class="fas fa-map-marked-alt text-gray-700 px-2"></i>
          Endereço
        </label>
        <p>{{ address }}</p>
      </div> -->

      <!-- <div class="flex justify-between">
        <div>{{ $t('page.register.sentence.i_am_an_agency') }}</div>
        <div>
          <i class="fas fa-check-circle text-green-500" v-if="user.isAgency"></i>
          <i class="fas fa-times-circle text-gray-300" v-else></i>
        </div>
      </div> -->

      <div class="flex justify-end">
        <button
          type="submit" 
          class="btn btn-primary"
        >
          {{ $t('action.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watchEffect } from 'vue'
import { currentUser } from '@/composables/user/currentUser'
import BaseInput from '../base/BaseInput.vue'
import { useToast } from '@/composables/useToast'
import { useI18n } from 'vue-i18n'

export default defineComponent({
  components: { BaseInput },
  setup () {
    const { t } = useI18n()
    
    const form = ref({
      name: '',
      // email: '',
      // document: '',
      phone: ''
    })
    const address = ref('')

    const { user, updateContactInfo } = currentUser()

    watchEffect(() => {
      const currentUser = user.value

      if (currentUser?.billing?.customer) {
        const customer = currentUser.billing.customer
        form.value = {
          name: customer.name,
          // document: customer.document,
          // email: customer.email,
          phone: customer.phone
        }
      }

      if (currentUser?.billing?.address) {
        const { street, number, complement, district, city, state, zip } = currentUser.billing.address
        address.value = `Rua ${street}. nº ${number}. ${complement}. ${district}, ${city} - ${state}. CEP:${zip}.`
      }
    })

    // watch(user, (currentUser) => {
    //   if (currentUser && currentUser.billing && currentUser.billing.customer) {
    //     const customer = currentUser.billing.customer
    //     form.value = {
    //       name: customer.name,
    //       document: customer.document,
    //       email: customer.email,
    //       phone: customer.phone
    //     }
    //   }

    //   if (currentUser && currentUser.billing && currentUser.billing.address) {
    //     const { street, number, complement, district, city, state, zip } = currentUser.billing.address
    //     address.value = `Rua ${street}. nº ${number}. ${complement}. ${district}, ${city} - ${state}. CEP:${zip}.`
    //   }
    // })

    const update = async () => {
      try {
        updateContactInfo({
          name: form.value.name,
          phone: form.value.phone
        })

        useToast.fire({
          icon: 'success',
          title: t('state.update_profile.title'),
        })
      } catch (error) {
        useToast.fire({
          icon: 'error',
          title: t('sentence.default_error_title'),
          text: t('sentence.default_error')
        })
      }
    }

    return {
      form,
      address,
      user,
      update
    }
  }
})
</script>
