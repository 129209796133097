import { computed, toRaw } from 'vue'
import { useStore } from 'vuex'

export const userProfiles = () => {
  const store = useStore()

  const profiles = computed(() => store.getters['profiles/all'])

  return {
    profiles: toRaw(profiles.value)
  }
}
